<style lang="scss">

</style>

<template>
  <div>
    <b-form-input v-model="name" placeholder="Enter role name" />

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': name.length === 0}"
                variant="primary" @click="addRole()"
      >Add new role</b-button>
    </section>

  </div>
</template>

<script>
  import RolesService from '../../../services/RolesService';

  export default {
    components: {},
    props: {},
    data() {
      return {
        name: '',
      };
    },
    mounted() {

    },
    methods: {
      addRole() {
        RolesService.addRole(this.name).then(() => {
          this.$toast.success(`Add role ${this.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not add role, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
    },
  };
</script>
