<!--eslint-disable-->
<template>
  <div class="roles-comp">
    <b-card v-if="$can('Create', 'Role')">
      <b-button @click="$bvModal.show('modal-add-role')" class="d-flex ml-auto btn-success">Add role</b-button>
    </b-card>

    <b-card>
      <b-card-text class="font-weight-bolder">Choose a role to manage</b-card-text>
      <b-form-select v-model="selectedRole" class="mb-2" @change="getRoleDetails()">
        <b-form-select-option value="" disabled>Select a role</b-form-select-option>
        <b-form-select-option v-for="role in roles" :key="role.id" :value="role">{{role.name}}</b-form-select-option>
      </b-form-select>

      <section v-if="roleToEdit">
        <p class="font-weight-bolder">Edit Role name</p>
        <b-form-input class="mb-2"
                      v-if="!roleToEdit.system" v-model="roleToEdit.name" placeholder="Enter role name" required></b-form-input>

        <p class="font-weight-bolder">Choose permissions for your role</p>

        <section class="d-flex flex-wrap mb-2">
          <label v-for="permission in permissions" :key="permission.id"
                 :class="{'disabled': roleToEdit.system || !$can('Update', 'Role')}" class="d-flex col-3 mb-1">
            <b-form-checkbox
              v-model="roleToEdit.permission_ids"
              :value="permission.id"
            ></b-form-checkbox>
            {{ permission.subject }} -  {{ permission.action }}
          </label>
        </section>


        <section class="d-flex full-width">
          <div class="ui-spacer"></div>
          <b-btn v-if="!roleToEdit.system && $can('Delete', 'Role')"
                 @click="$bvModal.show('modal-delete-role')"
                 variant="danger"
                 class="mr-2">Delete role</b-btn>
          <b-btn v-if="!roleToEdit.system && $can('Update', 'Role')"
                 :class="{'disabled': roleToEdit.name.length === 0}"
                 @click="updateRole(roleToEdit)"
                 class="btn-success">Update role</b-btn>

        </section>

      </section>
    </b-card>

    <b-modal id="modal-add-role" title="Add new role" hide-footer>
      <add-role @refresh="refreshRoles()" @close="closeModals()"></add-role>
    </b-modal>

    <b-modal id="modal-delete-role" title="Delete role" hide-footer>
      <delete-modal
        v-if="roleToEdit"
        @close="closeModals()" @delete="deleteRole(roleToEdit)"
        note="The role will be removed from any users attached"
        :subtitle="roleToEdit.name"
        title="Are you sure you wish to delete role"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import RolesService from '../../services/RolesService';
  import AddRole from './sections/AddRole.vue';
  import DeleteModal from '../../components/modals/DeleteModal.vue';

  export default {
    name: 'Roles',
    components: {
      AddRole,
      DeleteModal,
    },
    data() {
      return {
        selectedRole: '',
        roleToEdit: null, // different from selected as contains extra object info
        roles: [],
        permissions: [],
        headers: [
          {
            key: 'read',
            label: 'Read',
          },
          {
            key: 'create',
            label: 'Create',
          },
          {
            key: 'update',
            label: 'Update',
          },
          {
            key: 'delete',
            label: 'Delete',
          },
          {
            key: 'subject',
            label: 'Subject',
          },
        ],
      };
    },
    computed: {},
    mounted() {
      this.getRoles();
      this.getPermissions();
      // this.hasRole({})
    },
    methods: {
      refreshRoles() {
        this.closeModals();
        this.getRoles();
        this.selectedRole = '';
        this.roleToEdit = null;
      },
      closeModals() {
        this.$bvModal.hide('modal-add-role');
        this.$bvModal.hide('modal-delete-role');
      },
      getRoles() {
        RolesService.getRoles().then(res => {
          this.roles = res.data;
        }).catch(() => {
          this.$toast.error('Could not get roles, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      getPermissions() {
        RolesService.getPermissions().then(res => {
          this.permissions = res.data;
          this.sortPermissions();
        }).catch(() => {
          this.$toast.error('Could not get permissions, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      sortPermissions() {
        this.permissions.sort((a, b) => {
          if (a.subject < b.subject) { return -1; }
          if (a.subject > b.subject) { return 1; }
          return 0;
        })
      },
      getRoleDetails() {
        RolesService.getRole(this.selectedRole.id).then(res => {
          this.roleToEdit = res.data;

          if (!this.roleToEdit.permission_ids) { // set to array if null
            this.roleToEdit.permission_ids = [];
          }
        }).catch(() => {
          this.$toast.error('Could not get roles, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      updateRole(role) {
        RolesService.updateRole(role.id, {
          name: role.name,
          permissions: role.permission_ids,
        }).then(() => {
          this.$toast.success(`Updated role ${role.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshRoles();
        }).catch(() => {
          this.$toast.error('Could not update role, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      deleteRole(role) {
        RolesService.deleteRole(role.id).then(() => {
          this.$toast.success(`Deleted role ${role.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshRoles();
        }).catch(() => {
          this.$toast.error('Could not delete role, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
    },
  }
</script>

<style>

</style>
